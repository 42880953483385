import { PageTypes, Theme, ThemeContext } from "assets";
import { graphql } from "gatsby";
import { convertBreadcrumbNavigation } from "molecules";
import { convertConstellationGroup, PageLayout } from "organisms";
import PropTypes from "prop-types";
import React from "react";

const ConstellationGroupPage = ({ data, isChildObject }) => {
  const constellationGroupData = data?.craftAPI?.entry;

  return (
    <ThemeContext.Provider
      value={{
        theme: Theme.Black,
        fontType: "",
      }}
    >
      <PageLayout
        pageType={PageTypes.CONSTELLATION}
        theme={Theme.Black}
        title={constellationGroupData?.title}
      >
        {convertBreadcrumbNavigation({
          pageTitle: constellationGroupData.title,
          parents: constellationGroupData.parent,
        })}
        {convertConstellationGroup(constellationGroupData, isChildObject)}
      </PageLayout>
    </ThemeContext.Provider>
  );
};

export const constellationQuery = graphql`
  query ($uri: [String]) {
    craftAPI {
      entry(uri: $uri) {
        ...BreadcrumbNavigationFragment
        ... on CraftAPI_constellations_constellationGroup_Entry {
          id
          parent {
            uri
            children {
              ... on CraftAPI_constellations_constellationGroup_Entry {
                id
                date
                title
                uri
              }
            }
          }
          uri
          title
          descriptionHeader
          tag(label: true)
          objects: children {
            ...ConstellationChildObjectsFragment
          }
          shortDescription: constellationShortDescription
          text: constellationText
          date
          coverImage: constellationImage {
            ...ImageMetadataFragment
          }
          btnText
          reference {
            title
            uri
          }
        }
      }
    }
  }
`;

ConstellationGroupPage.propTypes = {
  data: PropTypes.shape({
    craftAPI: PropTypes.shape({
      entry: PropTypes.shape({
        objects: PropTypes.arrayOf(PropTypes.shape({})),
      }),
    }),
  }),
  isChildObject: PropTypes.bool,
};

ConstellationGroupPage.defaultProps = {
  isChildObject: false,
};

export default ConstellationGroupPage;
